import * as React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import ReactMarkdown from "react-markdown";
import { Link } from "gatsby";
import SEO from "src/components/SEO.js";

import Wrapper from "src/components/global/Wrapper.js";
import colors from "src/assets/styles/colors.js";
import BodyText from "src/components/typography/BodyText.js";
import BigTitle from "src/components/typography/BigTitle.js";
import Title from "src/components/typography/Title.js";
import Timeline from "src/components/global/Timeline.js";
import SliderBig from "src/components/global/SliderBig.js";
import mediaQuery from "src/assets/styles/mediaQuery";
import sizes from "src/assets/styles/sizes.js";
import Testimonials from "src/components/global/Testimonials.js";

import typographySizes from "../assets/styles/typographySizes";

const Header = styled(Wrapper)`
  padding-top: 320px;
  position: relative;
  @media (max-width: ${mediaQuery.tablet}) {
    padding-top: 108px;
  }
  @media (min-width: ${mediaQuery.max}) {
    padding-top: 22.185vw;
  }
`;

const HeaderTitle = styled(Title)`
  margin-bottom: 90px;
  display: inline-block;
  position: relative;

  ::after {
    content: "";
    position: absolute;
    bottom: -30px;
    left: 0;
    height: 1px;
    background-color: ${colors.mainColor};
    width: 8.33%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    ::after {
      width: 76px;
      bottom: -20px;
    }
    margin-bottom: 0px;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-bottom: 6.239vw;
  }
`;

const Year = styled.span`
  position: absolute;
  font-size: 200px;
  color: ${colors.lightGrey};
  z-index: -1;
  top: 0;
  right: 0;
  transform: translate(0, -55%);
  @media (max-width: ${mediaQuery.tablet}) {
    transform: translate(0, -45%);

    font-size: 100px;
  }
  @media (min-width: ${mediaQuery.max}) {
    font-size: 13.866vw;
  }
`;

const SliderWrapper = styled(Wrapper)`
  margin-top: 50px;
  margin-bottom: 70px;
  overflow-x: auto;

  padding-left: calc(((100vw - ${sizes.xlMargin * 2}px) * 0.0833)) !important;
  margin-left: ${sizes.xlMargin}px;
  width: calc(
    100% - ${sizes.xlMargin}px - ((100vw - ${sizes.xlMargin * 2}px) * 0.0833)
  );

  @media (max-width: ${mediaQuery.desktop}) {
    width: calc(
      100% - ${sizes.bigMargin}px - ((100vw - ${sizes.xlMargin * 2}px) * 0.0833)
    );
    margin-left: ${sizes.bigMargin}px;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - ${sizes.smallMargin}px);
    margin-left: ${sizes.smallMargin}px;
    padding-left: 0 !important;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-top: 6.933vw;
    margin-bottom: 4.853vw;
    margin-left: 9.706vw;
    padding-left: calc(((100vw - 9.706vw * 2) * 0.0833)) !important;

    width: calc(100% - 9.706vw);
    width: calc(100% - 9.706vw - ((100vw - 9.706vw * 2) * 0.0833));
  }
`;

const OfficeWrapper = styled(Wrapper)`
  display: flex;
  margin-bottom: 80px;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 50px;
    flex-direction: column;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-bottom: 5.546vw;
  }
`;

const OfficeSubtitle = styled.h3`
  position: relative;
  top: 35px;
  font-size: ${typographySizes.s}px;
  width: calc(8.33% - 20px);
  margin-right: 20px;
  text-align: right;
  color: ${colors.mainColor};
  line-height: 1.33;
  font-weight: 300;
  @media (max-width: ${mediaQuery.tablet}) {
    top: 0px;
    width: 100%;
    text-align: left;
  }
  @media (max-width: ${mediaQuery.mobile}) {
    font-size: ${typographySizes.xs}px;
    line-height: 1.5;
  }
  @media (min-width: ${mediaQuery.max}) {
    font-size: ${typographySizes.maxS}vw;
    line-height: 1.33;
  }
  @media (min-width: ${mediaQuery.max}) {
    top: 2.079vw;
    font-size: ${typographySizes.maxS}vw;
    width: calc(8.33% - 1.386vw);
    margin-right: 1.386vw;
  }
`;

const OfficeContent = styled.div`
  width: 91.66%;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    width: 100%;
  }
`;

const OfficeContentTop = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 50px;
  @media (min-width: ${mediaQuery.max}) {
    margin-bottom: 3.466vw;
  }
`;

const OfficeTitle = styled(BigTitle)`
  width: 385px;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 140px;
  }
  @media (min-width: ${mediaQuery.max}) {
    width: 26.692vw;
  }
`;

const OfficeLink = styled(Link)`
  margin-left: 85px;
  text-decoration: underline;
  font-size: ${typographySizes.s}px;
  color: ${colors.darkGrey};
  position: relative;
  bottom: 12px;
  font-weight: 300;
  @media (max-width: ${mediaQuery.tablet}) {
    bottom: 0px;
    margin-left: 20px;
  }
  @media (max-width: ${mediaQuery.mobile}) {
    font-size: ${typographySizes.xs}px;
    line-height: 1.5;
  }
  @media (min-width: ${mediaQuery.max}) {
    font-size: ${typographySizes.maxS}vw;
    line-height: 1.33;
    margin-left: 6.586vw;
    bottom: 0.831vw;
  }
`;

const OfficeFirstParagraph = styled.div`
  margin-left: 8.33%;
  width: 40%;
  .marginP {
    p {
      margin-bottom: 20px;
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - 40px);
    margin-left: 20px;
  }
  @media (min-width: ${mediaQuery.max}) {
    .marginP {
      p {
        margin-bottom: 1.386vw;
      }
    }
  }
`;

const OfficeSecondParagraph = styled.div`
  margin-left: 4.165%;
  width: 40%;
  .marginP {
    p {
      margin-bottom: 20px;
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - 40px);
    margin-left: 20px;
  }
  @media (min-width: ${mediaQuery.max}) {
    .marginP {
      p {
        margin-bottom: 1.386vw;
      }
    }
  }
`;

const HistoryWrapper = styled(Wrapper)`
  margin-bottom: 180px;

  overflow-x: auto;

  margin-left: ${sizes.xlMargin}px;

  width: calc(100% - ${sizes.xlMargin}px);
  @media (max-width: ${mediaQuery.desktop}) {
    width: calc(100% - ${sizes.bigMargin}px);
    margin-left: ${sizes.bigMargin}px;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - ${sizes.smallMargin * 2}px);
    margin-left: ${sizes.smallMargin}px !important;
    margin-bottom: 60px;
    overflow-x: inherit;
  }
  @media (min-width: ${mediaQuery.max}) {
    /* margin-top: 6.933vw; */
    margin-bottom: 12.479vw;
    /* margin-left: 9.706vw; */
    width: calc(100% - 9.706vw);
    margin-left: 9.706vw;
  }
`;

const Bg = styled.div`
  background-color: ${colors.lightGrey};
  position: relative;
  width: 100%;
  margin-bottom: 85px;
  padding-bottom: 100px;
  ::before {
    position: absolute;
    width: 100%;

    content: "";
    top: 0px;
    height: 250px;

    left: 0;
    background-color: ${colors.white};
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 50px;
    padding-bottom: 50px;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-bottom: 5.893vw;
    padding-bottom: 6.933vw;
    ::before {
      height: 17.332vw;
    }
  }
`;

const PersonsWrapper = styled(Wrapper)`
  margin-bottom: 195px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  z-index: 10;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 60px;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-bottom: 13.519vw;
  }
`;

const Person = styled.div`
  width: 41.66%;
  margin-right: 8.33%;
  margin-bottom: 50px;
  :nth-last-of-type(1),
  :nth-last-of-type(2) {
    margin-bottom: 0;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 75%;
    margin-right: 25%;
    margin-bottom: 35px;
    :nth-last-of-type(1) {
      margin-bottom: 0;
    }
    :nth-last-of-type(2) {
      margin-bottom: 35px;
    }
  }
  @media (max-width: ${mediaQuery.mobile}) {
    width: 100%;
    margin-right: 0%;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-bottom: 3.466vw;
  }
`;

const StyledTitle = styled(Title)`
  margin-top: 25px;
  @media (min-width: ${mediaQuery.max}) {
    margin-top: 1.73vw;
  }
`;
const WarehouseWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column-reverse;
  }
`;

const WarehouseTextWrap = styled.div`
  width: 41.66%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-top: 25px;
    align-self: flex-start;
  }
`;

const WarehouseTitleDesktop = styled(BigTitle)`
  width: 290px;
  margin-bottom: 90px;
  p {
    color: ${colors.darkBlue};
  }
  strong {
    vertical-align: super;
    font-size: ${typographySizes.l / 2}px;
    font-weight: 300;
    color: ${colors.darkBlue};
    background-color: transparent;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
  @media (min-width: ${mediaQuery.max}) {
    width: 20.105vw;
    strong {
      font-size: ${typographySizes.maxL / 2}vw;
    }
  }
`;

const WarehouseTitleMobile = styled(BigTitle)`
  display: none;
  @media (max-width: ${mediaQuery.tablet}) {
    display: block;
    margin-bottom: 30px;
    width: 280px;
    align-self: flex-start;
    color: ${colors.mainColor};
    p {
      color: ${colors.darkBlue};
    }
    strong {
      vertical-align: super;
      font-size: ${typographySizes.l / 2}px;
      color: ${colors.darkBlue};
      font-weight: 300;
      background-color: transparent;
    }
  }
  @media (max-width: ${mediaQuery.mobile}) {
    width: 100px;

    strong {
      vertical-align: super;
      font-size: ${typographySizes.sm / 2}px;
      font-weight: 200;
      background-color: transparent;
    }
  }
`;

const WarehouseSubTitle = styled(Title)`
  p {
    color: ${colors.mainColor};
  }
  strong {
    vertical-align: super;
    color: ${colors.mainColor};
    font-size: ${typographySizes.m / 2}px;
    font-weight: 200;
    background-color: transparent;
  }
  @media (min-width: ${mediaQuery.max}) {
    strong {
      font-size: ${typographySizes.maxM / 2}vw;
    }
  }
`;

const WarehouseImg = styled(Img)`
  grid-area: img;
  width: 58.33%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const WarehouseFirstDesccription = styled(BodyText)`
  width: 41.66%;
  margin-right: 8.33%;
  align-self: flex-start;
  margin-top: 80px;
  @media (max-width: ${mediaQuery.tablet}) {
    order: -1;
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-top: 10px;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-top: 5.546vw;
  }
`;

const WarehouseSecondDesccription = styled(BodyText)`
  width: 50%;
  margin-top: 80px;

  align-self: flex-start;
  @media (max-width: ${mediaQuery.tablet}) {
    order: -2;
    width: calc(100% - 40px);
    margin-left: 20px;

    margin-top: 0px;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-top: 5.546vw;
  }
`;

const TestimonialsWrapper = styled(Wrapper)`
  margin-bottom: 100px;
  @media (min-width: ${mediaQuery.max}) {
    margin-bottom: 6.933vw;
  }
`;
class OnasPage extends React.Component {
  render() {
    return (
      <>
        <SEO
          title={this.props.data.strapiAboutPage.SEO.Title}
          description={this.props.data.strapiAboutPage.SEO.Description}
        />
        <Header>
          <HeaderTitle
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            <Year>{this.props.data.strapiAboutPage.Year}</Year>
            {this.props.data.strapiAboutPage.Top_text}
          </HeaderTitle>
        </Header>
        <SliderWrapper>
          <SliderBig images={this.props.data.strapiAboutPage.Slider} />
        </SliderWrapper>
        <OfficeWrapper>
          <OfficeSubtitle
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            {this.props.data.strapiAboutPage.Office_subtitle}
          </OfficeSubtitle>
          <OfficeContent>
            <OfficeContentTop>
              <OfficeTitle
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                {this.props.data.strapiAboutPage.Office_title}
              </OfficeTitle>
              <OfficeLink
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-duration="1000"
                to="/kontakt"
              >
                Mapa
              </OfficeLink>
            </OfficeContentTop>
            <OfficeFirstParagraph>
              <BodyText
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                <ReactMarkdown
                  className={"marginP"}
                  source={
                    this.props.data.strapiAboutPage.Office_first_paragraph
                  }
                />
              </BodyText>
            </OfficeFirstParagraph>
            <OfficeSecondParagraph>
              <BodyText
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                <ReactMarkdown
                  className={"marginP"}
                  source={
                    this.props.data.strapiAboutPage.Office_second_paragraph
                  }
                />
              </BodyText>
            </OfficeSecondParagraph>
          </OfficeContent>
        </OfficeWrapper>
        <HistoryWrapper>
          <BigTitle
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            {this.props.data.strapiAboutPage.History_title}
          </BigTitle>
          <Timeline years={this.props.data.strapiAboutPage.Timeline}></Timeline>
        </HistoryWrapper>
        <Bg>
          <PersonsWrapper>
            {this.props.data.strapiAboutPage.Person.map((document) => (
              <Person
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                <Img fluid={document.Img.childImageSharp.fluid} />
                <StyledTitle> {document.Name}</StyledTitle>
                <BodyText> {document.Position}</BodyText>
              </Person>
            ))}
          </PersonsWrapper>
          <WarehouseWrapper>
            <WarehouseTextWrap>
              <WarehouseTitleDesktop
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                <ReactMarkdown
                  source={this.props.data.strapiAboutPage.Warehouse_title}
                />
              </WarehouseTitleDesktop>
              <WarehouseSubTitle
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-duration="1000"
                Color={colors.mainColor}
              >
                <ReactMarkdown
                  source={this.props.data.strapiAboutPage.Warehouse_subtitle}
                />
              </WarehouseSubTitle>
            </WarehouseTextWrap>
            <WarehouseImg
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="1000"
              fluid={
                this.props.data.strapiAboutPage.Warehouse_Img.childImageSharp
                  .fluid
              }
            />
            <WarehouseTitleMobile
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="1000"
            >
              <ReactMarkdown
                source={this.props.data.strapiAboutPage.Warehouse_title}
              />
            </WarehouseTitleMobile>
            <WarehouseFirstDesccription
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="1000"
            >
              {this.props.data.strapiAboutPage.Warehouse_first_description}
            </WarehouseFirstDesccription>
            <WarehouseSecondDesccription
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="1000"
            >
              {this.props.data.strapiAboutPage.Warehouse_second_description}
            </WarehouseSecondDesccription>
          </WarehouseWrapper>
        </Bg>
        <TestimonialsWrapper>
          <Testimonials data={this.props.data.allStrapiTestimonials} />
        </TestimonialsWrapper>
      </>
    );
  }
}

export const ProjektowanieQuery = graphql`
  query OnasQuery {
    strapiAboutPage {
      History_title
      Office_first_paragraph
      Office_second_paragraph
      Office_subtitle
      Office_title
      Top_text
      Year
      Slider {
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      Timeline {
        Number
        Text
      }
      Top_text
      Year
      Person {
        Name
        Position
        Img {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      Warehouse_first_description
      Warehouse_second_description
      Warehouse_subtitle
      Warehouse_title
      Warehouse_Img {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      SEO {
        Description
        Title
      }
    }
    allStrapiTestimonials {
      edges {
        node {
          Date
          Description
          Name
        }
      }
    }
  }
`;

export default OnasPage;
