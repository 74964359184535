import styled from "styled-components";
import typographySizes from "src/assets/styles/typographySizes.js";
import colors from "src/assets/styles/colors.js";
import mediaQuery from "src/assets/styles/mediaQuery";

const BigTitle = styled.h2`
  font-size: ${typographySizes.l}px;
  color: ${(props) => (props.Color ? props.Color : colors.darkGrey)};
  line-height: 1.2;
  font-weight: 200;
  @media (max-width: ${mediaQuery.mobile}) {
    font-size: ${typographySizes.sm}px;
  }
  @media (min-width: ${mediaQuery.max}) {
    font-size: ${typographySizes.maxL}vw;
  }
`;

export default BigTitle;
