import React from "react";

import Arrow from "src/assets/svg/arrow.svg";
import BodyText from "src/components/typography/BodyText.js";

import styled from "styled-components";
import BigTitle from "src/components/typography/BigTitle.js";

import mediaQuery from "src/assets/styles/mediaQuery";
import sizes from "src/assets/styles/sizes.js";
import colors from "../../assets/styles/colors";

const SliderWrapper = styled.div`
  width: calc(100%);
`;

const YearsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  /* position: relative; */
  overflow-x: hidden;
  height: auto;
  position: relative;
  width: calc(100%);
  margin-top: 80px;
  ::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    top: 58%;
    background-color: ${colors.mainColor};
  }
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    margin-top: 35px;
    overflow: inherit !important;
    ::after {
      left: 175px;
      width: 1px;
      height: 100%;
      top: 0;
    }
  }
  @media (max-width: ${mediaQuery.mobile}) {
    ::after {
      left: 75px;
    }
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-top: 5.546vw;
    ::after {
  
    height: 0.0693vw;

  }
  }
`;

const YearBar = styled.div`
  background-color: ${colors.mainColor};
  width: 1px;
  height: 50px;
  margin: 15px 0;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-shrink: 0;

    width: 40px;
    height: 1px;
    margin: 0px 10px;
  }
  @media (min-width: ${mediaQuery.max}) {
    width: 0.0693vw;
  height: 3.466vw;
  margin: 1.039vw 0;
  }
`;

const YearWrapper = styled.div`
  width: 210px;
  flex-grow: 1;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;

  padding-right: 80px;
  /* justify-content: center; */
  margin-top: 80px;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  :nth-last-of-type(even) {
    margin-top: 0px;
    ${YearBar} {
      height: 180px;
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: row;
    width: 100%;
    padding-right: 0px;
    justify-content: flex-start;
    margin-bottom: 50px;
    margin-top: 0px;

    :nth-last-of-type(even) {
      ${YearBar} {
        height: 1px;
      }
    }
    :last-of-type {
      margin-bottom: 0px;
    }
  }
  @media (min-width: ${mediaQuery.max}) {
    width: 14.559vw;

  padding-right: 5.546vw;

  margin-top:  5.546vw;
  :nth-last-of-type(even) {
      ${YearBar} {
        height: 12.479vw;
      }
    }
  }
`;

const Year = styled(BigTitle)`
  @media (max-width: ${mediaQuery.tablet}) {
    width: 150px;
    flex-shrink: 0;
  }
  @media (max-width: ${mediaQuery.mobile}) {
    width: 55px;
  }
`;

const YearText = styled(BodyText)`
  text-align: center;
  @media (max-width: ${mediaQuery.tablet}) {
    text-align: left;
  }
`;

const NavWrapper = styled.div`
  width: calc(100% - ${sizes.xlMargin}px);
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  @media (max-width: ${mediaQuery.desktop}) {
    width: calc(100% - ${sizes.bigMargin}px);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-top: 2.079vw;
    width: calc(100% - 9.702vw);
  }
`;

const ArrowBox = styled.button`
  height: 55px;
  width: 55px;
  border: solid 1px ${colors.mainColor};
  transition: all 0.5s;
  cursor: pointer;
  &.disable {
    opacity: 0.3;
    transition: all 0.5s;
  }
  :hover {
    position: relative;
    transform: translateY(-10px);
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .arrow {
    path {
      stroke: ${(props) => (props.blue ? "#fff" : "#707070")};
    }
  }
  @media (min-width: ${mediaQuery.max}) {
    height: 3.813vw;
    width: 3.813vw;
    :hover {
      transform: translateY(-0.693vw);
    }
    .arrow {
      width: 1.159vw;
      height: 0.628vw;
    }
  }
`;

const ArrowBoxLeft = styled(ArrowBox)`
  transform: rotate(90deg);

  :hover {
    transform: translateY(-5px) rotate(90deg);
  }
  @media (min-width: ${mediaQuery.max}) {
    transform: translateY(-0.346vw) rotate(90deg);
  }
`;

const ArrowBoxRight = styled(ArrowBox)`
  transform: rotate(-90deg);

  :hover {
    transform: translateY(-5px) rotate(-90deg);
  }
  @media (min-width: ${mediaQuery.max}) {
    transform: translateY(-0.346vw) rotate(-90deg);
  }
`;

class Timeline extends React.Component {
  state = {
    curentProject: 0,
    nextDisable: false,
    prevDisable: true,
    yearsLength: this.props.years.length,
  };
  nextProject = () => {
    console.log(this.state.curentProject);
    if (this.state.curentProject >= this.state.yearsLength - 1) {
    } else {
      this.setState({
        curentProject: this.state.curentProject + 1,
      });
    }
    if (this.state.curentProject >= this.state.yearsLength - 2) {
      this.setState({
        nextDisable: true,
      });
    } else {
    }
    if (this.state.curentProject >= 0) {
      this.setState({
        prevDisable: false,
      });
    } else {
    }
  };
  prevProject = () => {
    console.log(this.state.curentProject);
    if (this.state.curentProject <= 0) {
    } else {
      this.setState({
        curentProject: this.state.curentProject - 1,
      });
    }

    if (this.state.curentProject >= this.state.yearsLength - 1) {
      this.setState({
        nextDisable: false,
      });
    } else {
    }
    if (this.state.curentProject <= 1) {
      this.setState({
        prevDisable: true,
      });
    } else {
    }
  };
  render() {
    return (
      <SliderWrapper>
        <YearsWrapper>
          {this.props.years.map((document) => (
            <YearWrapper
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="1000"
              style={{
                transform: `translateX(calc(${
                  this.state.curentProject * -100
                }%   ))`,
              }}
            >
              <Year>{document.Number}</Year>
              <YearBar />
              <YearText>{document.Text}</YearText>
            </YearWrapper>
          ))}
        </YearsWrapper>
        <NavWrapper>
          <ArrowBoxLeft
            blue={this.props.blue ? true : false}
            onClick={this.prevProject}
            className={this.state.prevDisable ? "disable" : ""}
          >
            <Arrow />
          </ArrowBoxLeft>
          <ArrowBoxRight
            blue={this.props.blue ? true : false}
            onClick={this.nextProject}
            className={this.state.nextDisable ? "disable" : ""}
          >
            <Arrow />
          </ArrowBoxRight>
        </NavWrapper>
      </SliderWrapper>
    );
  }
}

export default Timeline;
