import React from "react";

import Img from "gatsby-image";
import Arrow from "src/assets/svg/arrow.svg";

import styled from "styled-components";

import mediaQuery from "src/assets/styles/mediaQuery";
import sizes from "src/assets/styles/sizes.js";
import colors from "../../assets/styles/colors";

const SliderWrapper = styled.div`
  width: calc(100%);
`;

const ImgWrapper = styled.div`
  display: flex;
  flex-direction: row;
  /* position: relative; */
  overflow-x: hidden;
  height: auto;

  width: calc(100%);
`;

const ImgTextWrapper = styled.div`
  margin-right: 15px;
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);

  @media (min-width: ${mediaQuery.max}) {
    margin-right: 1.039vw;
  }
`;

const StyledImg = styled(Img)`
  width: calc(((100vw - ${sizes.xlMargin * 2}px) * 0.833) - 10px) !important;
  height: calc(((100vw - ${sizes.xlMargin * 2}px) * 0.5) - 10px) !important;

  img {
    object-fit: cover;
  }
  @media (max-width: ${mediaQuery.desktop}) {
    width: calc(((100vw - ${sizes.bigMargin * 2}px) * 0.833) - 10px) !important;
    height: calc(((100vw - ${sizes.bigMargin * 2}px) * 0.5) - 10px) !important;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(
      ((100vw - ${sizes.smallMargin * 2}px) * 0.833) - 10px
    ) !important;
    height: calc(
      ((100vw - ${sizes.smallMargin * 2}px) * 0.5) - 10px
    ) !important;
  }
  @media (max-width: ${mediaQuery.mobile}) {
    width: calc(((100vw - ${sizes.smallMargin * 2}px)) - 10px) !important;
    height: calc(
      ((100vw - ${sizes.smallMargin * 2}px)) * 0.6 - 10px
    ) !important;
  }
  @media (min-width: ${mediaQuery.max}) {
    width: calc(((100vw - 9.706vw * 2) * 0.833) - 0.693vw) !important;
    height: calc(((100vw - 9.706vw * 2) * 0.5) - 0.693vw) !important;
  }
  object-fit: cover;
`;

const NavWrapper = styled.div`
  width: calc(100% - ${sizes.xlMargin}px);
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  @media (max-width: ${mediaQuery.desktop}) {
    width: calc(100% - ${sizes.bigMargin}px);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - ${sizes.smallMargin}px);
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-top: 2.079vw;
    width: calc(100% - 9.702vw);
  }
`;

const ArrowBox = styled.button`
  height: 55px;
  width: 55px;
  border: solid 1px ${colors.mainColor};
  transition: all 0.5s;
  cursor: pointer;
  &.disable {
    opacity: 0.3;
    transition: all 0.5s;
  }
  :hover {
    position: relative;
    transform: translateY(-10px);
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .arrow {
    path {
      stroke: ${(props) => (props.blue ? "#fff" : "#707070")};
    }
  }
  @media (min-width: ${mediaQuery.max}) {
    height: 3.813vw;
    width: 3.813vw;
    :hover {
      transform: translateY(-0.693vw);
    }
    .arrow {
      width: 1.159vw;
      height: 0.628vw;
    }
  }
`;

const ArrowBoxLeft = styled(ArrowBox)`
  transform: rotate(90deg);

  :hover {
    transform: translateY(-5px) rotate(90deg);
  }
  @media (min-width: ${mediaQuery.max}) {
    transform: translateY(-0.346vw) rotate(90deg);
  }
`;

const ArrowBoxRight = styled(ArrowBox)`
  transform: rotate(-90deg);

  :hover {
    transform: translateY(-5px) rotate(-90deg);
  }
  @media (min-width: ${mediaQuery.max}) {
    transform: translateY(-0.346vw) rotate(-90deg);
  }
`;

class SliderBig extends React.Component {
  state = {
    curentProject: 0,
    nextDisable: false,
    prevDisable: true,
    imgLength: this.props.images
      ? this.props.images.length
      : this.props.imgsTexts.length,
  };
  nextProject = () => {
    console.log("testnext");
    if (this.state.curentProject >= this.state.imgLength - 1) {
    } else {
      this.setState({
        curentProject: this.state.curentProject + 1,
      });
    }
    if (this.state.curentProject >= this.state.imgLength - 2) {
      this.setState({
        nextDisable: true,
      });
    } else {
    }
    if (this.state.curentProject >= 0) {
      this.setState({
        prevDisable: false,
      });
    } else {
    }
  };
  prevProject = () => {
    if (this.state.curentProject <= 0) {
    } else {
      this.setState({
        curentProject: this.state.curentProject - 1,
      });
    }

    if (this.state.curentProject >= this.state.imgLength - 1) {
      this.setState({
        nextDisable: false,
      });
    } else {
    }
    if (this.state.curentProject <= 1) {
      this.setState({
        prevDisable: true,
      });
    } else {
    }
  };
  render() {
    return (
      <SliderWrapper
        data-sal="slide-up"
        data-sal-easing="ease"
        data-sal-duration="1000"
      >
        <ImgWrapper>
          {this.props.images.map((document) => (
            <ImgTextWrapper
              style={{
                transform: `translateX(calc(${
                  this.state.curentProject * -100
                }% - 15px * ${this.state.curentProject} ))`,
              }}
            >
              <StyledImg fluid={document.localFile.childImageSharp.fluid} />
            </ImgTextWrapper>
          ))}
        </ImgWrapper>
        <NavWrapper>
          <ArrowBoxLeft
            blue={this.props.blue ? true : false}
            onClick={this.prevProject}
            className={this.state.prevDisable ? "disable" : ""}
          >
            <Arrow />
          </ArrowBoxLeft>
          <ArrowBoxRight
            blue={this.props.blue ? true : false}
            onClick={this.nextProject}
            className={this.state.nextDisable ? "disable" : ""}
          >
            <Arrow />
          </ArrowBoxRight>
        </NavWrapper>
      </SliderWrapper>
    );
  }
}

export default SliderBig;
