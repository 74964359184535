import React from "react";

import styled from "styled-components";
import Title from "src/components/typography/Title.js";
import BodyText from "src/components/typography/BodyText.js";

import colors from "src/assets/styles/colors.js";
import mediaQuery from "src/assets/styles/mediaQuery";

import typographySizes from "src/assets/styles/typographySizes";

const Wrapper = styled.div``;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 50px 0;
  border-bottom: solid 1px ${colors.lightGrey};
  :last-of-type {
    border-bottom: none;
  }

  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    padding: 30px 0;
  }
  @media (min-width: ${mediaQuery.max}) {
    padding: 3.466vw 0;
    border-bottom: solid 0.069vw ${colors.lightGrey};
  }
`;

const Name = styled(BodyText)`
  font-weight: 500;
`;

const Date = styled(BodyText)``;

const Information = styled.div`
  @media (max-width: ${mediaQuery.tablet}) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
`;

const Testimonial = styled(BodyText)`
  width: 66.66%;
  font-size: ${typographySizes.sm}px;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
  @media (max-width: ${mediaQuery.mobile}) {
    font-size: ${typographySizes.xs}px;
  }
  @media (min-width: ${mediaQuery.max}) {
    font-size: ${typographySizes.maxSm}vw;
  }
`;

const TestimonialsWrapper = ({ data }) => (
  <Wrapper>
    <Title data-sal="slide-up" data-sal-easing="ease" data-sal-duration="1000">
      Jesteśmy dumni z naszych realizacji, <br /> robimy to dla Was.
    </Title>
    {data.edges.map((document) => (
      <Row data-sal="slide-up" data-sal-easing="ease" data-sal-duration="1000">
        <Information>
          <Name>{document.node.Name}</Name>
          <Date>{document.node.Date}</Date>
        </Information>
        <Testimonial>{document.node.Description}</Testimonial>
      </Row>
    ))}
  </Wrapper>
);

export default TestimonialsWrapper;
